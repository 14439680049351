import { createContext, useReducer } from "react";
import { getUser } from "./user-storage";
import userReducer from "./UserReducer";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const user = getUser();

  const initialState = {
    user: user.user ?? null,
    pass: user.pass ?? null,
    baseURL: user.baseURL ?? null,
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  const saveUser = (user, pass, baseURL) => {
    dispatch({
      type: "SAVE_USER",
      payload: {
        user,
        pass,
        baseURL,
      },
    });
  };

  const clearUser = () => {
    dispatch({
      type: "CLEAR_USER",
    });
  };

  return (
    <UserContext.Provider
      value={{
        userInfo: {
          user: state.user,
          pass: state.pass,
          baseURL: state.baseURL,
        },
        saveUser,
        clearUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
