import React from "react";

function LiveChannelItem({ item, onClick }) {
  return (
    <div
      className="flex-col h-48 bg-rose-950 rounded-md flex justify-center items-center cursor-pointer hover:border-rose-600 hover:border-[1px] hover:shadow-lg hover:shadow-rose-600"
      onClick={() => onClick(item)}
    >
      <img
        src="https://placehold.co/400"
        className="w-full h-28 mb-2"
        alt="placholder"
      />

      <div className="text-gray-100 font-bold text-sm">{item.name}</div>
    </div>
  );
}

export default LiveChannelItem;
