import React from "react";

const data = [
  { id: 1, title: "Home", link: "/" },
  { id: 7, title: "Hotel", link: "/hotel" },
  { id: 2, title: "Live TV", link: "/" },
  { id: 3, title: "Movies", link: "#" },
  { id: 4, title: "Shows", link: "#" },
  { id: 5, title: "My Account", link: "#" },
  { id: 6, title: "Logout", link: "/logout" },
];

function Sidebar() {
  return (
    <>
      <div className="w-[300px] h-screen bg-rose-800 float-end">
        <ul className="flex flex-col mt-4 px-4 font-medium">
          {data.map((d) => (
            <li key={d.id} className="pb-2">
              <a
                href={d.link}
                className="block py-2 pl-3 pr-4 text-gray-100 hover:bg-rose-600 rounded-md"
              >
                {d.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
