import { Outlet, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import UserContext from "../context/user/UserContext";

const AppLayout = () => {
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const { user, pass, baseURL } = userInfo;
    if (user === null || pass === null || baseURL === null) {
      navigate("/login");
    }
  });

  return (
    <section className="overflow-hidden bg-gradient-to-b from-rose-900 from-10% to-rose-950">
      <div className="w-full">
        <div className="flex flex-row">
          <div className="w-full">
            <Outlet />
          </div>

          <Sidebar />
        </div>
      </div>
    </section>
  );
};

export default AppLayout;
