import React from "react";
import ReactHlsPlayer from "@gumlet/react-hls-player";

function Player({ link }) {
  return (
    <>
      <div className="flex items-center justify-center">
        <ReactHlsPlayer
          src={link}
          autoPlay={true}
          controls={true}
          width="98%"
          height="98%"
        />
      </div>
    </>
  );
}

export default Player;
