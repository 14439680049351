export const USER_KEYS = {
  user: "user",
  pass: "pass",
  baseURL: "baseURL",
};

export const saveUser = (user, pass, url) => {
  localStorage.setItem(USER_KEYS.user, user);
  localStorage.setItem(USER_KEYS.pass, pass);
  localStorage.setItem(USER_KEYS.baseURL, url);
};

export const getUser = () => {
  const user = localStorage.getItem(USER_KEYS.user);
  const pass = localStorage.getItem(USER_KEYS.pass);
  const baseURL = localStorage.getItem(USER_KEYS.baseURL);

  return { user, pass, baseURL };
};

export const clearUser = () => {
  localStorage.clear();
};
