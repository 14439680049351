import LiveChannel from "../components/LiveChannel/LiveChannel";
import Hotel from "../pages/Hotel/Hotel";
import Login from "../pages/Login/Login";
import Logout from "../pages/Login/Logout";
import AppLayout from "./AppLayout";

export const routes = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <LiveChannel />,
      },
      {
        path: "/hotel",
        element: <Hotel />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
];
