import React, { useContext, useEffect } from "react";
import UserContext from "../../context/user/UserContext";
import { useNavigate } from "react-router-dom";

function Logout() {
  const { clearUser } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    handleClearUser();
  });

  const handleClearUser = () => {
    clearUser();
    navigate("/login");
  };

  return <div>Logout...</div>;
}

export default Logout;
