import { clearUser, saveUser } from "./user-storage";

const userReducer = (state, action) => {
  switch (action.type) {
    case "SAVE_USER":
      saveUser(
        action.payload.user,
        action.payload.pass,
        action.payload.baseURL
      );

      return {
        ...state,
        user: action.payload.user,
        pass: action.payload.pass,
        baseURL: action.payload.baseURL,
      };

    case "CLEAR_USER":
      clearUser();
      return {
        ...state,
        user: null,
        pass: null,
        baseURL: null,
      };

    default:
      return state;
  }
};

export default userReducer;
