import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

function Modal({ visible, onDismiss, title, children }) {
  return (
    <>
      <div
        className={`${
          visible ? "flex" : "hidden"
        } w-full bg-[#00000080] h-full absolute m-0 top-0 left-0 justify-center items-center`}
      >
        <div className="w-[100%] h-[100%] bg-stone-950 p-4 overflow-scroll">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex items-center justify-between mb-4">
              <h3 className="font-semibold uppercase text-xl text-rose-100 ml-6">
                {title}
              </h3>
              <XMarkIcon
                className="w-6 h-6 text-white cursor-pointer"
                onClick={onDismiss.bind(null, null)}
              />
            </div>
          </div>

          {children}
        </div>
      </div>
    </>
  );
}

export default Modal;
