import React from "react";
import hotelMenu from "../../assets/hotel-menu.jpg";

function Hotel() {
  return (
    <section className="flex h-screen justify-end items-center">
      <div className="w-full h-full overflow-hidden">
        <img src={hotelMenu} className="w-full h-full object-contain" />
      </div>
    </section>
  );
}

export default Hotel;
