import React, { useContext, useEffect, useState } from "react";
import loginBG from "../../assets/login-bg.jpg";
import UserContext from "../../context/user/UserContext";
import { useNavigate } from "react-router-dom";

function Login() {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [serverUrl, setServerURL] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  const { userInfo, saveUser } = useContext(UserContext);

  useEffect(() => {
    if (
      userInfo.user !== null &&
      userInfo.pass !== null &&
      userInfo.baseURL !== null
    ) {
      navigate("/");
    }
  }, [userInfo]);

  const handleSaveUser = (e) => {
    e.preventDefault();
    setErrorMessage(null);

    if (user !== "" && pass !== "" && serverUrl !== "") {
      saveUser(user, pass, serverUrl);
    } else {
      setErrorMessage("*All fields are required.");
    }
  };

  return (
    <section className="flex h-screen justify-end items-center">
      <div className="w-[60%] h-full overflow-hidden">
        <img src={loginBG} />
      </div>
      <div className="flex h-full w-[40%] items-center bg-contain bg-gradient-to-bl from-rose-800 from-10% to-rose-950">
        <div className="flex w-full p-8">
          <div className="w-full">
            <form className="flex flex-col gap-3">
              {errorMessage !== null ? (
                <div className="text-red-300 py-4">{errorMessage}</div>
              ) : null}

              <div className="flex flex-row gap-4 items-center my-2">
                <label className="w-32 font-bold text-gray-200">Username</label>
                <input
                  type="text"
                  name="user"
                  className="bg-gray-50 p-1 w-80 rounded-md border-[1px] border-rose-200"
                  onChange={(e) => setUser(e.target.value)}
                />
              </div>

              <div className="flex flex-row gap-4 items-center my-2">
                <label className="w-32 font-bold text-gray-200">Password</label>
                <input
                  type="password"
                  name="pass"
                  className="bg-gray-50 p-1 w-80 rounded-md border-[1px] border-rose-200"
                  onChange={(e) => setPass(e.target.value)}
                />
              </div>

              <div className="flex flex-row gap-4 items-center my-2">
                <label className="w-32 font-bold text-gray-200">
                  Server URL
                </label>
                <input
                  type="text"
                  name="serverURL"
                  className="bg-gray-50 p-1 w-80 rounded-md border-[1px] border-rose-200"
                  onChange={(e) => setServerURL(e.target.value)}
                />
              </div>

              <div className="flex flex-row gap-4 items-center my-8">
                <button
                  type="submit"
                  onClick={handleSaveUser}
                  className="w-40 p-2 text-white rounded-md bg-rose-500 hover:bg-rose-600"
                >
                  Get Started
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
