import React, { useContext, useEffect, useState } from "react";
import { getRequest } from "../../services/request/api";
import LiveChannelItem from "./LiveChannelItem";
import Modal from "../Modal/Modal";
import Player from "../Player/Player";
import UserContext from "../../context/user/UserContext";
import { useNavigate } from "react-router-dom";

function LiveChannel() {
  const [channelList, setChannelList] = useState([]);
  const [showPlayer, setShowPlayer] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { userInfo, clearUser } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      userInfo.user !== null &&
      userInfo.pass !== null &&
      userInfo.baseURL !== null
    ) {
      fetchLiveChannels();
    }
  }, []);

  const fetchLiveChannels = async () => {
    setErrorMessage(null);

    try {
      const response = await getRequest("get_live_streams", userInfo);
      setChannelList(response);
    } catch (err) {
      console.log(err);
      setErrorMessage(
        "Could not access resources with the provided information."
      );
    }
  };

  const handleChannelClick = (channel) => {
    setSelectedChannel(channel);
    setShowPlayer(true);
  };

  const dismissModal = () => {
    setSelectedChannel(null);
    setShowPlayer(false);
  };

  const handleClearUser = () => {
    clearUser();
    navigate("/login");
  };

  return (
    <>
      <div className="h-screen pl-2 py-2 pr-1 overflow-scroll">
        {errorMessage !== null ? (
          <div className="flex flex-col gap-8 w-full h-full items-center justify-center">
            <div className="text-red-400 text-3xl">{errorMessage}</div>
            <div>
              <button
                type="submit"
                className="bg-rose-500 rounded-md text-white py-4 px-8 text-xl"
                onClick={handleClearUser}
              >
                TRY AGAIN
              </button>
            </div>
          </div>
        ) : null}

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {channelList.map((channel) => (
            <LiveChannelItem
              key={channel.num}
              item={channel}
              onClick={handleChannelClick}
            />
          ))}
        </div>

        <Modal
          visible={showPlayer}
          title={selectedChannel?.name}
          onDismiss={dismissModal}
        >
          {selectedChannel != null ? (
            <Player
              link={`http://skadtv.com:25461/live/arun/arun/${selectedChannel.stream_id}.m3u8`}
            />
          ) : null}
        </Modal>
      </div>
    </>
  );
}

export default LiveChannel;
