const headers = {
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
};

//const baseURL = "http://skadtv.com:25461";

export const getRequest = async (action, userInfo) => {
  try {
    const url = `${userInfo.baseURL}/player_api.php?username=${userInfo.user}&password=${userInfo.pass}&action=${action}`;
    //const url = `http://xstream.bytemantra.com/live-tv.php?baseURL=${userInfo.baseURL}&username=${userInfo.user}&password=${userInfo.pass}&action=${action}`;
    //console.log(url);
    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    const statusCode = response.status;

    if (statusCode >= 200 && statusCode < 300) {
      const json = await response.json();
      return json;
    } else {
      throw new Error("Not Authorized");
    }
  } catch (err) {
    throw err;
  }
};
